import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import { Link } from "gatsby";
import Layout from "../../layouts/index";
import tower from "../../images/tower.jpg";
import indaka from "../../images/indaka-raigama.png";
import IOT from "../../images/IOT-1024x432.jpg";
import device from "../../images/device-1024x353.png";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft Announces the Release of 5G Capabilities on its XG Platform"
        description="After months of R&amp;D plus hard work, iTelaSoft (iTelaSoft Pty Ltd) announced the immediate availability of 5G testing and monitoring functionalities on its XG platform from November 2020."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <img alt="5G Capabilities" src={tower} />
        </div>

        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">NEWS | NOVEMBER 16, 2020</div>
              <h1>
                iTelaSoft Announces the Release of 5G Capabilities on its XG
                Platform
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container skwirk-page xg-flatform pt-4 mb-6">
        <div className="section-1">
          After months of R&amp;D plus hard work, iTelaSoft (iTelaSoft Pty Ltd)
          announced the immediate availability of 5G testing and monitoring
          functionalities on its XG platform from November 2020.
        </div>
        <div className="section-2 mb-3">
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex">
                <div className="skwirk p-2 text-center">
                  <img alt="Indaka Raigama" src={indaka} />
                  <div className="utext mt-1">
                    Indaka Raigama, CEO of iTelaSoft
                  </div>
                </div>

                <div className="p-md-2">
                  <p className="para1">
                    “Until now, iTelaSoft’s XG platform was capable of testing
                    and monitoring 2G, 3G, 4G, and Narrowband cellular networks.
                    Hereinafter, with the addition of latest 5G NR capabilities,
                    our XG Test Platform becomes one of the most convenient
                    all-in-one networking testing tools on the market, since it
                    now allows testing and monitoring the complete range of
                    cellular technologies”, Co-Founder & CEO of iTelaSoft Indaka
                    Raigama mentioned.
                  </p>
                  <p className="para2">
                    From the perspective of telecommunications companies, which
                    are either planning or already deploying 5G infrastructure,
                    the ability to test high-bandwidth, low-latency data
                    transmissions in a controlled lab environment or at a
                    production site represents a huge advantage. Thanks to the
                    XG platform from iTelaSoft they can efficiently overcome
                    some of the most common 5G implementation challenges such
                    as,
                  </p>
                </div>
              </div>
              <p className="mb-para2">
                From the perspective of telecommunications companies, which are
                either planning or already deploying 5G infrastructure, the
                ability to test high-bandwidth, low-latency data transmissions
                in a controlled lab environment or at a production site
                represents a huge advantage. Thanks to the XG platform from
                iTelaSoft they can efficiently overcome some of the most common
                5G implementation challenges such as,
              </p>

              <ul className="p-1">
                <li>Performing comprehensive 5G signal reliability tests</li>
                <li>
                  Easing the validation of MIMO and beamforming configurations
                </li>
                <li>Testing 5G speed performance</li>
                <li>
                  Performing 5G network simulations in controlled laboratory
                  conditions, using XGP advanced testing and monitoring features
                  to assist technicians in identifying anomalies
                </li>
                <li>5G network availability testing and monitoring</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-3 mb-5">
          <div className="img-fluid">
            <img alt="Automation Device" src={device} />
            <div className="utext">5G Capable XG Test Automation Device</div>
          </div>
        </div>
        <div className="section-4 mb-5">
          <p>
            The recent update of the XG platform with next-generation 5G
            capabilities is further proof of the commitment of the iTelaSoft
            technical team to keep this platform at the forefront of network
            monitoring and testing tools. Amidst many challenges imposed during
            the COVID era, iTelaSoft R&D team was able to design and manufacture
            the new device adding 5G capability in record time. The devices was
            tested in 5G deployments in Sri Lanka, Spain and Germany in the
            pilot stage. iTelaSoft has now made the general availability of the
            5G testing and monitoring to their customers.
          </p>
          <p>
            The IoT innovations are spear headed by a team of young engineers
            lead by IoT Head, Chrishmal Fernando. “The platform is not limited
            to 5G. Over the last four years, XG Test Platform covered a series
            of capabilities from validating Voice, IP & Non-IP Messaging,
            Broadband and Narrowband Data in cellular networks. Our platform
            consists of radio test equipment and a distributed test framework
            software that allows network technicians and test professionals to
            be productive by drastically reducing manual testing involved in
            setting up a cell. Not only for testing, we have employed our
            platform for ongoing monitoring of cellular services and
            applications in many occasions”, Mr. Fernando added.
          </p>
        </div>
        <div className="section-5 mb-5">
          <div className="group img-fluid">
            <img alt="IoT Team" src={IOT} />
            <div className="utext">
              Chrishmal Fernando (far left), Head of IoT, and the iTelaSoft IoT
              Team
            </div>
          </div>
        </div>
        <div className="section-6">
          <p>
            iTelaSoft pledge is to continue leading the telecommunications
            landscape by offering innovative and useful tools that facilitate
            the daily operations of its customers.
          </p>
          <p>
            Learn more about the{" "}
            <span>
              {" "}
              <a
                href="/products/xg-automation-platform/"
                rel="noreferrer noopener"
                aria-label="xg-automation-platform"
                target="_blank"
              >
                XG Test Platform
              </a>
            </span>
          </p>
        </div>
        <NFooter
          prv1="/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
          prv2="/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
          next1="/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
          next2="/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
          titlel="How migrating to AWS eliminated tech worries at Skwirk"
          titler="Revolutionizing the Coconut Industry through Digital EMP"
        />
      </div>
    </Layout>
  );
};
export default News;
